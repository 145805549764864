// Import Icons
import { TbError404 } from "react-icons/tb";

// Import Link
import { Link } from 'react-router-dom';

// Import Components
import Footer from "../components/Footer";

// Error Component
const Error = () => {

    return (
        <div>
            <div className="error-container"> 
                <TbError404 className="error-404"/>
                <h2>¡ Error, recurso no encontrado ¡</h2>

                <Link className='home-button' to="/">Volver al inicio</Link>
            </div>
            <Footer/>
        </div>   
    );
};

// Export Error Component
export default Error;