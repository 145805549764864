// Import React Library
import React from 'react';

// Import React DOM
import ReactDOM from 'react-dom/client';

// Import Routing System
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// Import Pages
import Error from './pages/Error';

// Import Styles
import './sass/index.scss';

// Import Tailwind CSS
import './styles/tailwind.css';

// Import Components
import App from './App';

// Router Component
const router = createBrowserRouter([
  {
    path: '',
    element: <App />,
    errorElement : <Error />
  }
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <RouterProvider router={router}/>
  </React.StrictMode>
);

