// Import React Library
import React from 'react';

// Navbar Component
const Navbar = () => {
    return (
        <nav className='navbar-section'>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="283.000000pt" height="248.000000pt" viewBox="0 0 283.000000 248.000000" preserveAspectRatio="xMidYMid meet">
                <defs>
                    <linearGradient id="myGradient" gradientTransform="rotate(90)">
                    <stop offset="17%" stop-color="hsla(194, 72%, 44%, 1)" />
                    <stop offset="100%" stop-color="hsla(219, 69%, 42%, 1)" />
                    </linearGradient>
                </defs>
                <g transform="translate(0.000000,248.000000) scale(0.100000,-0.100000)" fill="url(#myGradient)" stroke="none">
                    <path d="M612 1588 l-144 -141 119 -118 118 -118 265 259 265 259 -240 0 -240 0 -143 -141z" />
                    <path d="M1400 1464 l0 -238 145 -145 145 -146 115 115 c63 63 115 118 115 122 0 7 -500 521 -512 526 -5 2 -8 -103 -8 -234z" />
                    <path d="M2430 1650 c-25 -27 -139 -147 -254 -265 l-210 -216 115 -115 c62 -63 118 -113 124 -113 5 1 71 64 145 141 l135 139 3 239 c2 132 -1 240 -5 239 -4 0 -28 -22 -53 -49z" />
                    <path d="M315 1300 l-110 -110 110 -110 110 -110 110 110 110 110 -110 110 -110 110 -110 -110z" />
                    <path d="M585 1050 l-117 -118 143 -140 144 -141 238 -1 c130 0 237 1 237 3 0 2 -119 118 -263 259 l-264 257 -118 -119z" />
                    <path d="M1834 1008 c-57 -55 -104 -106 -104 -112 0 -6 48 -60 108 -119 l107 -107 108 108 c59 59 107 112 107 117 0 12 -202 215 -213 215 -5 0 -55 -46 -113 -102z" />
                </g>
            </svg>

            <ul>
                <li className='font-bold font-medium text-gray-500 hover:text-gray-900 border-b-2 border-transparent hover:border-blue-900'>
                    <a href='#'>Inicio</a>
                </li>

                <li className='font-bold font-medium text-gray-500 hover:text-gray-900 border-b-2 border-transparent hover:border-blue-900'>
                    <a href='#services'>Servicios</a>
                </li>
        
                <li className='font-bold font-medium text-blue-900 hover:text-blue-900 border-b-2 border-transparent hover:border-blue-900'>
                    <a href="#contact-form">Contacto</a>
                </li>
            </ul>
            
        </nav>
    );
};

// Export Navbar Component
export default Navbar;